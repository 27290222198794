import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

import useNavigate from "hooks/Navigate";
import apis from '../../services';
import CssBaseline from "@mui/material/CssBaseline";
import {AddStoreForm} from "../../components/forms";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNewSidebar from 'components/admin/AdminNewSidebar';
import { Header } from 'components';
import { useSelector } from 'react-redux';



const INITIAL_FORM_STATE = {
    title: "",
    description: "",
    location: {
        state: "",
        city: "",
        address: "",
        postalCode: ""
    },
    image: "",
}

function AddUpdateStore() {
    const [storeData, setStoreData] = useState(INITIAL_FORM_STATE)
    const {id: storeId} = useParams();
    
    const {adminUser} = useSelector((store) => store.admin);
   const navigate = useNavigate();
    const onChangeHandler = (e) => {
        const {name, value, files} = e.target;
        if (files)
            return setStoreData((prevState) => ({...prevState, [name]: files[0]}));
        if (['state', 'city', 'address', 'postalCode'].includes(name))
            return setStoreData((prevState) => ({...prevState, location: {...prevState.location, [name]: value}}));
        setStoreData((prevState) => ({...prevState, [name]: value}));
    };

    const fetchStoreDetails = async (storeId) => {
        try {
            const res = await apis.getStore(storeId)
            if (res.data.status) {
                setStoreData(res.data.store)
            }
        } catch (e) {
            console.error('ERROR in fetching store details', e);
        }
    };

    const onCreateStoreHandler = async (data) => {
        try {
            const res = await apis.createStore(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                const redirect = adminUser?.type === "owner" ?  "/admin/stores" : "/sub-admin/stores";
                navigate(redirect);
                setStoreData(INITIAL_FORM_STATE)
            }

        } catch (e) {
            console.error('ERROR on Create Store', e);
        }
    };
    const onUpdateStoreHandler = async (data) => {
        try {
            const res = await apis.updateStore(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                const redirect = adminUser?.type === "owner" ?  "/admin/stores" : "/sub-admin/stores";
                navigate(redirect);
                setStoreData(INITIAL_FORM_STATE)
                
            }
        } catch (e) {
            console.error('ERROR on update Store', e);
        }
    }

    useEffect(() => {
        (async () => {
            if (storeId)
                await fetchStoreDetails(storeId);

        })()
    }, [storeId]);

    const onSubmitHandler = async (data) => {
        if (storeId) await onUpdateStoreHandler(storeData)
        else await onCreateStoreHandler(storeData)
    }

    return (
        <Box className="dashboard-main">
            <AdminNewSidebar/>
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
                className="custom-scroll"
            >
                <Header/>
                <Container>
                <h3 className="section-title dark-text text-start mb-0">{storeId ? 'Update' : 'Add'} Store</h3>
                    <AddStoreForm
                        formData={storeData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={storeId ? 'Update' : 'Add'}
                    />
                </Container>
            </Box>
            </div>
        </Box>
    );
}

export default AddUpdateStore;
