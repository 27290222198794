import { Footer, MainHeader, TeamCard } from "components";
import { teamMembers } from "dummyData";
import { useTranslation } from "react-i18next";
import React, {useEffect, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import imageUrl from "utils/imageUrl";
import cms from "../services/cms";
import CustomHelmet from "../components/CustomHelmet";
import {useParams} from "react-router-dom";
import detectLang from "utils/detectLang";

function About() {
  const { lang: _lang } = useParams();
  const lang = _lang || "en";
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [seos, setSeos] = useState({});

  
  const [showDropdown, setShowDropdown] = useState(false);
 
  const [section, setSection] = useState([]);
  const [setting, setSetting] = useState({});
  const { language, setLanguage, t } = useTranslation();
  const fetchSeo = async () => {
    const res = await cms.getSeo('about-us');
    setSeos(res?.data?.data);
  };
  const fetchSection = async () => {
    const res = await cms.getSection(
      "work,join_section,team,investor,social",
      "home,all"
    );
    setSection(res?.data?.data);
  };


  const fetchSetting = async () => {
    const res = await cms.getSetting(
      "header,service_setting,join_setting,team,investor,ceo,notice,thinking,footer,work,banner,footer",
      "all,home"
    );
    setSetting(res?.data?.data);
  };
  useEffect(() => {
    (async () => {
      await fetchSection();
      await fetchSetting();
      await fetchSeo();
    })();
  }, []);
  return (
    <>
    <div onClick={()=>{ if(showDropdown) setShowDropdown(!showDropdown)}}>
      <div className="page-banner" >
        <MainHeader showDropdown={showDropdown} setShowDropdown={setShowDropdown}  />
        {
          seos && seos?.seos ? <CustomHelmet seo={seos?.seos} lang={lang}  /> : ''
        }
        <img
          src={imageUrl("about_img.webp")}
          className="background-img"
          alt=""
        />
        <div className="page-banner-body">
          <p className="page-banner-title">{t("titles.about_us")}</p>
        </div>
      </div>
      <section className="py-5">
        <Container>
          <Row className="align-items-center mobile-col-reverse">
            <Col md={5}>
              <p className="title text-start fw-semibold mb-3">
                {t("titles.about_jtc")} <span className="red">(JTC)</span>
              </p>
              <p className="normal-p">{t("descs.about")}</p>
            </Col>
            <Col md={1}></Col>
            <Col md={6} className="d-flex justify-content-center pb-5">
              <img
                src={imageUrl("coffee_logo.png")}
                alt=""
                className="about-logo"
              />
            </Col>
          </Row>
        </Container>
      </section>
      
      <section className="custom-section text-center team-section">
            <Container>
              <p className="section-title">
                {detectLang(setting?.team, "heading", lang)}
              </p>
              <p>{detectLang(setting?.team, "sub_heading", lang)}</p>
              <Slider {...settings}>
                {section?.team?.map((member) => (
                  <TeamCard data={member} lang={lang} />
                ))}
              </Slider>
            </Container>
          </section>
      <Footer />
      </div>
    </>
  );
}

export default About;
