import React, { useState } from "react";
import { useSelector } from "react-redux";
import imageUrl from "utils/imageUrl";
import { useTranslation } from "react-i18next";
import "./sidebar.css";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, setLanguage, t } = useTranslation();

  const { user } = useSelector((store) => store.user);

  return (
    <>
      <div className={`sidebar custom-scroll ${isOpen ? "open" : ""}`}>
        <div className="logo-details logo-icon primary-logo">
          <div className="sb-icon-wrapper">
            <i className="bx bxl-c-plus-plus icon"></i>
          </div>
          <img src={imageUrl("logo-icon.png")} alt="" />
        </div>
        <div className="logo-details">
          <div className="sb-icon-wrapper">
            <i className="bx bxl-c-plus-plus icon"></i>
          </div>
          <img
            src={imageUrl("bars-icon.png")}
            alt=""
            id="btn"
            onClick={() => setIsOpen((prevState) => !prevState)}
          />
        </div>
        <ul className="nav-list">
          <li>
            <a href="we-are-java">
              <div className="sb-icon-wrapper">
                <img src={imageUrl("hands-icon.png")} alt="" />
              </div>
              <span className="links_name">{t("buttons.we_are_java")}</span>
            </a>
            <span className="tooltip">{t("buttons.we_are_java")}</span>
          </li>
          {/* <li>
       <a href="/menu">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('menu-icon.png')} alt="" />
      </div>
         <span className="links_name">Menu</span>
       </a>
       <span className="tooltip">Menu</span>
     </li> */}
          {/* <li>
       <a href="/shop">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('shop-icon.png')} alt="" />
      </div>
         <span className="links_name">Shop</span>
       </a>
       <span className="tooltip">Shop</span>
     </li> */}
          <li>
            <a href="/offices">
              <div className="sb-icon-wrapper">
                <img src={imageUrl("location-icon.png")} alt="" />
              </div>
              <span className="links_name">{t("buttons.branch_offices")}</span>
            </a>
            <span className="tooltip">{t("buttons.branch_offices")}</span>
          </li>
          <li>
            <a href="/invest">
              <div className="sb-icon-wrapper">
                <img src={imageUrl("dollar-icon.png")} alt="" />
              </div>
              <span className="links_name">{t("buttons.investment")}</span>
            </a>
            <span className="tooltip">{t("buttons.investment")}</span>
          </li>
          {/* <li>
       <a href="/race">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('flags-icon.png')} alt="" />
      </div>
         <span className="links_name">Race</span>
       </a>
       <span className="tooltip">Race</span>
     </li> */}
          {/* <li>
       <a href="/blog">
       <div className="sb-icon-wrapper">  
       <img src={imageUrl('b-icon.png')} alt="" />
      </div>
         <span className="links_name">Blog</span>
       </a>
       <span className="tooltip">Blog</span>
     </li> */}
          <li>
            <a href="/contact-us">
              <div className="sb-icon-wrapper">
                <img src={imageUrl("call-icon.png")} alt="" />
              </div>
              <span className="links_name">{t("buttons.contact")}</span>
            </a>
            <span className="tooltip">{t("buttons.contact")}</span>
          </li>
          {user?.isVerified ? (
            <>
              {" "}
              <li>
                <a href="/dashboard">
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("shop-icon.png")} alt="" />
                  </div>
                  <span className="links_name">{t("buttons.dashboard")}</span>
                </a>
                <span className="tooltip">{t("buttons.dashboard")}</span>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>

      {/* <script>
  let sidebar = document.querySelector(".sidebar");
  let closeBtn = document.querySelector("#btn");
  let searchBtn = document.querySelector(".bx-search");

  closeBtn.addEventListener("click", ()=>{
    sidebar.classList.toggle("open");
    menuBtnChange();//calling the function(optional)
  });

  searchBtn.addEventListener("click", ()=>{ // Sidebar open when you click on the search iocn
    sidebar.classList.toggle("open");
    menuBtnChange(); //calling the function(optional)
  });

  // following are the code to change sidebar button(optional)
  function menuBtnChange() {
   if(sidebar.classList.contains("open")){
     closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
   }else {
     closeBtn.classList.replace("bx-menu-alt-right","bx-menu");//replacing the iocns class
   }
  }
  </script> */}
    </>
  );
};

export default Sidebar;
