// -----------------------------------------------------------------------
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { Box, Container, CssBaseline, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import reduxApis from "redux/apis";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";

import { EntityModal, InvestCard } from "../../components";
import apis from "../../services";

import useNavigate from "hooks/Navigate";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import SearchBar from "../../components/SearchBar";
import InvestOptionsModal from "../../components/modals/InvestOptionsModal";
import KYCModal from "components/modals/KYCModal";
import SignatureModal from "components/modals/SignatureModal";
import SignatureSuccessModal from "components/modals/SignatureSuccessModal";

// -----------------------------------------------------------------------

const mdTheme = createTheme();

export default function Dashboard() {
  // ----------------------------------------------------------------------

  const drawerWidth = 240;

  const navigate = useNavigate();
  const searchRef = useRef("");

  const searchParams = new URLSearchParams(useLocation().search);
  let query = Object.fromEntries(searchParams);

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [investmentModal, setInvestmentModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const toggleModal = (id) => {
    const selectedStore = stores.find((store) => store._id === id);
    setStore(selectedStore);
    setShowModal((prevState) => !prevState);
  };

  const toggleSuccessModal = () => {
    setSuccessModal((prevState) => !prevState);
  };

  const toggleInvestmentModal = (type = "close") =>
  {
   
    
    if(type === "open")
    {
         setSignatureModal(true);
         setInvestmentModal(false)
    }
    else if(type === "success")
    {
        setSuccessModal(true)
        setSignatureModal(false);
        setInvestmentModal(false)
    }
    else if(user?.isKYCCompleted === 'pending')
    {
        setInvestmentModal((prevState) => !prevState);
        setSignatureModal(false);

    }
   else if(user?.isKYCCompleted === "progress")
    {
         setSignatureModal((prevState) => !prevState);
         setInvestmentModal(false)
    }
  }
    
  const fetchActiveStore = async () => {
    const response = await apis.getAllActiveStores();
    if (response.status === 200) {
      setStores(response.data.stores);
    }
  };

  const handleDrawerToggle = () => setMobileOpen((prevState) => !prevState);

  const stateFiltration = (store) => {
    if (query.hasOwnProperty("state")) {
      return query["state"].includes(store.location.state);
    } else {
      return store;
    }
  };

  const searchFiltration = (stores) => {
    return Object.values(stores).some((store) => {
      if (typeof store === "string")
        return store.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      else return searchFiltration(store);
    });
  };

  const searchStoreHandler = () => {
    setSearch(searchRef.current.value);
  };

  const investCardProps = { toggleModal, toggleInvestmentModal };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    (async () => {
      try {
        await fetchActiveStore();
      } catch (e) {
        console.log("Error fetching Stores");
      }
    })();
  }, []);

  const NoStoreFound = ({ t }) => (
    <Grid item xs justifyContent="center">
      <h3 className="common-heading text-center pt-5">
        {t("messages.no_stores")}
      </h3>
    </Grid>
  );

  // const drawer = (
  //    <div>
  //         <List>
  //             <ListItem disablePadding>
  //                 <ListItemButton onClick={() => navigate(`?`)}>
  //                     <ListItemText primary="All"/>
  //                 </ListItemButton>
  //             </ListItem>
  //             {STATES.map((state) => (
  //                 <ListItem key={state} disablePadding selected={state === query.state}>
  //                     <ListItemButton onClick={() => navigate(`?state=${state}`)}>
  //                         <ListItemText primary={state} />
  //                     </ListItemButton>
  //                 </ListItem>
  //             ))}
  //         </List>
  //     </div>
  // );

  const renderStores = (t) => {
    if (stores.length === 0) return <NoStoreFound t={t} />;

    const stateFiltrationResult = _.filter(stores, stateFiltration);

    if (stateFiltrationResult.length === 0) return <NoStoreFound t={t} />;

    const searchFiltrationResult =
      stateFiltrationResult.filter(searchFiltration);

    if (searchFiltrationResult.length === 0) return <NoStoreFound t={t} />;

    return searchFiltrationResult.map((store, index) => (
      <InvestCard key={index} {...investCardProps} {...store} />
    ));
  };

  // -------------------------------------------------------------------------

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => setOpen(!open);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const { user } = useSelector((store) => store.user);
  const { wallet } = useSelector((store) => store.wallet);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const res = await dispatch(reduxApis.getWallet(user?.id));
    })();
  }, []);

  const dispatch = useDispatch();

  const popOpen = Boolean(anchorEl);
  const id = popOpen ? "simple-popover" : undefined;

  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              <h3 className="dashboard-title">{t("titles.stores")}</h3>

              {/* ----------------------------------------------------------------------------------------------------- */}
              <Box sx={{ display: "flex" }} className="mt-4">
                <CssBaseline />
                {/* <Box
                    component="nav"
                    sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                    aria-label="mailbox folders"
                >
                    The implementation can be swapped with js to avoid SEO duplication of links.
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: {xs: 'block', sm: 'none'},
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        PaperProps={{
                            sx: {
                                backgroundColor: 'inherit',
                                zIndex: 1
                            }
                        }}
                        sx={{
                            display: {xs: 'none', sm: 'block'},
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                position: 'relative',
                                overflowY: 'auto',
                                height: '70vh'
                            },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box> */}
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                  }}
                  className="user-invest-main"
                >
                  <Grid container direction="column" className="test">
                    <Grid item>
                      <SearchBar
                        searchRef={searchRef}
                        handleSearch={searchStoreHandler}
                      />
                    </Grid>
                    <Grid item>
                      <Grid item container className="stores-list">
                        {renderStores(t)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {user?.isKYCCompleted === "pending" && (
                <KYCModal
                  toggleModal={toggleInvestmentModal}
                  status={investmentModal}
                  userId={user?.id}
                  signature={false}
                />
              )}
            
            
              {/* {user?.isKYCCompleted === "progress" && (
                <SignatureModal
                  toggleModalSignature={toggleInvestmentModal}
                  status={signatureModal}
                  userId={user?.id}
                />
              )} */}

                {/* <SignatureSuccessModal
                  toggleModalSuccess={toggleSuccessModal}
                  status={successModal}
                  userId={user?.id}
                /> */}

              {store && (
                <EntityModal
                  status={showModal}
                  toggleModal={toggleModal}
                  {...store}
                  getStores={fetchActiveStore}
                />
              )}
              {/* ------------------------------------------------------------------------------------------ */}
            </Container>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
}
