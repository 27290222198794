import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "components/admin/AdminSidebar";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "services";
import TransactionTable from "../../components/datagrid/TransactionTable";
import { DataGrid } from "@mui/x-data-grid";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import DeleteModal from "components/modals/DeleteModal";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Table, Form, Row, Col } from "react-bootstrap";

const Transactions = () => {

  const [startDate, setStartDate] = useState(null);
const [endDate, setEndDate] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const { language, setLanguage, t } = useTranslation();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [transactionId, setTransactionId] = useState(null);

  // const fetchTransactions = async () => {
  //   const { data } = await apis.getTransactions();
  //   if (data?.status) {
  //     setTransactions(data.transactions);
  //   }
  // };



  const fetchTransactions = async () => {
    const queryParams = {};
    if (startDate) {
      queryParams.startDate = startDate;
    }
    if (endDate) {
      queryParams.endDate = endDate;
    }

    const { data } = await apis.getTransactions(queryParams);
    if (data?.status) {
      setTransactions(data.transactions);
    }
  };

  const handleClose = () => {
    setShow(false);
    setTransactionId("");
    setLoader(false);
  };

  const deleteHandler = async (id) => {
    setLoader(true);
   
    const res = await apis.adminDeleteTransactionUser(id);
    if (res.data.status) {
      toast.success(res.data.message);
      await fetchTransactions();
    }
    else {
      toast.error(res.data?.message);
    }
    handleClose();
    setLoader(false);
  };



  useEffect(() => {
    (async () => {
      await fetchTransactions();
    })();
  }, []);

  
  const openModal = (id) => {
    setTransactionId(id);
    setShow(true);
  };

  let tables;
  if (searchQuery) {
    tables = TransactionTable(filteredTransactions, {
      openModal,
    });
  }
  else {
    tables = TransactionTable(transactions, {
      openModal,
    });
  }

  const downloadTransaction = () => {
   let filteredData;
    if(searchQuery)
    {
      filteredData = filteredTransactions.map(({ isDeleted , _id , updatedAt  , ...rest }) => rest);
  
    }
    else{
      filteredData = transactions.map(({ isDeleted , _id , updatedAt  , ...rest }) => rest);
  
    }
    
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Fields");
    XLSX.writeFile(workbook, "investor_data.xlsx");
  };


  
  const filterTransactions = () => {
    const filtered = transactions?.filter((transaction) => {
      const search = `${transaction.userName} ${transaction.storeName}`.toLowerCase();
      return search?.includes(searchQuery.toLowerCase()) ;
    });
    setFilteredTransactions(filtered);
  };
  
  return (
    <Box className="dashboard-main">
      <AdminNewSidebar />
      <div className="dashboard-section-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="custom-scroll"
        >
          <Header />
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.transactions")}</h3>
                {/* <h3 className="dashboard-title">{t("titles.users")}</h3> */}
                  <button className="simple-btn my-2 flex-wrap" onClick={downloadTransaction}>
                <img src="/images/excel.svg" alt="" />
              </button>
              </div>
              <Col xs={12} md={6} lg={4} className="mt-3 p-0">
                <Form.Group className="my-2">
                  <Form.Label>Search by User Name or Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter user name or store name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={filterTransactions}
                  />
                </Form.Group>
              </Col>


              {/* <div className="flex-with-title">
              <h3 className="dashboard-title">{t("titles.users")}</h3>
              <input              
              type='date'
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
              type='date'
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <button className="simple-btn my-2 flex-wrap" onClick={fetchTransactions}>
                Apply Filters
              </button>
            </div> */}

            <Grid
              item
              container
              className="mt-4"
              justifyContent="flex-end"
              sx={{ height: "600px" }}
            >
              <DataGrid
                rows={tables?.rows}
                columns={tables?.columns}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </Grid>

            <DeleteModal
                show={show}
                handleClose={handleClose}
                handleSubmit={deleteHandler}
                loader={loader}
                id={transactionId}
                title={"Delete Transaction"}
              />
          </Container>
        </Box>
      </div>
      
    </Box>
  );
};

export default Transactions;
