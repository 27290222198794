import React, {Fragment} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {STATES} from "../../assets/constants";

const AddStoreForm = ({formData, submitHandler, onChangeHandler, heading}) => {

    const submit = (e) => {
        e.preventDefault();
        submitHandler(formData);
        e.currentTarget.reset();
    }

    return (
        <Fragment>
            <Form onSubmit={submit} className='my-5'>
                
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name='title' placeholder='Store title' required
                                          onChange={onChangeHandler} value={formData.title}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" name='description' placeholder='Store Description'
                                          onChange={onChangeHandler} value={formData.description}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Select name='state' onChange={onChangeHandler} value={formData.location.state}>
                                <option >Select State...</option>
                                {STATES.map(state => <option key={state}>{state}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name='city' placeholder='City'
                                          onChange={onChangeHandler} value={formData.location.city}/>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="postalCode">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" name='postalCode' placeholder='Postal Code'
                                          onChange={onChangeHandler} value={formData.location.postalCode}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="totalShares">
                            <Form.Label>Total Shares</Form.Label>
                            <Form.Control type="number" name='totalShares' placeholder='Total Shares'
                                          onChange={onChangeHandler} value={formData.totalShares}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="sharePerPrice">
                            <Form.Label>Share Per Price</Form.Label>
                            <Form.Control type="number" name='sharePerPrice' placeholder='Share Per Price'
                                          onChange={onChangeHandler} disabled value={20}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="postalCode">
                            <Form.Label>Store Image</Form.Label>
                            <Form.Control type="file" name='image' placeholder='Image'
                                          onChange={onChangeHandler}/>
                        </Form.Group>
                    </Col>
                    
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control as="textarea" name='address' placeholder='address'
                                          onChange={onChangeHandler} value={formData.location.address}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Button className='w-100' variant="outline-danger" type='submit'> {heading} Store </Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}

export default AddStoreForm
